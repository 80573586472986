import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { NextLoading } from '@/utils/loading/loading'
import store from '@/store'
import NProgress from 'nprogress'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        meta: { isAuth: false },
        component: () => import(/* webpackChunkName: "login" */ '@/layout/login.vue')
    },
    {
        path: '/main',
        name: 'main',
        meta: { isAuth: false },
        redirect: '/dashboard',
        component: () => import( /* webpackChunkName: "main" */ '@/layout/main.vue'),
        children: [
            { path: '/job', meta: { isAuth: false, perm: '',name:'职位管理' }, component: () => import( /* webpackChunkName: "pageAuth" */ '@/views/job/jobList.vue') },
            { path: '/vitae', meta: { isAuth: false, perm: '',name:'推荐牛人' }, component: () => import( /* webpackChunkName: "pageAuth" */ '@/views/vitae/vitaeList.vue') },
            { path: '/chat', meta: { isAuth: false, perm: '',name:'沟通'}, component: () => import( /* webpackChunkName: "pageAuth" */ '@/views/chat/chat.vue') },
            { path: '/jobChat',name: 'jobChat', meta: { isAuth: false, perm: '',name:'职位互动'}, component: () => import( /* webpackChunkName: "pageAuth" */ '@/views/jobChat/jobChat.vue') },
            { path: '/dashboard',name: 'dashboard', meta: { isAuth: false, perm: '',name:'控制台'}, component: () => import( /* webpackChunkName: "pageAuth" */ '@/views/home/dashboard.vue') },
        ]
    },
    {
        path: '/noAuth',
        name: 'noAuth', 
        meta: { isAuth: false },
        component: () => import('@/layout/noAuth.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: { isAuth: false },
        component: () => import('@/layout/404.vue')
    },
    // 所有未定义路由，全部重定向到404页，必须放在最后
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_WEB),
  routes
})
router.beforeEach((to, from, next) => {
    let path = to.path
    let name: any = to.meta.name
    store.commit('activeMenu',{path:path,name:name})
    next()
})
router.afterEach(() => {
    NProgress.done()
    NextLoading.done()
})
export default router
