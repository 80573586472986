import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入vxe-table组件
import VXETable from '@/plugins/index'
import 'vxe-table/lib/style.css'
//引入nprogress样式
import 'nprogress/nprogress.css'
import './assets/scss/index.scss'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VXETable)
app.use(Viewer, {
    defaultOptions: {
        zIndex: 9999,
        toolbar: false,
        transition: false,
        title: false,
        navbar: false,
        // button:false,
        scalable: false,
        rotatable: false,
        tooltip: false,
        inline: false,
    }
})
app.mount('#app')