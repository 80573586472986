import { createStore } from 'vuex'
import persistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import Base64 from 'base-64';
var ls = new SecureLS({ isCompression: false })

export default createStore({
    state: {
        token: {
            accessToken: '',
            expireTime: 0,
            refreshToken: '',
        },
        userInfo: {} as any,
        systemConfig: {} as any,
        activeMenu: {
            path: '',
            name: ''
        },
        areaData: [] as any,
        emojiList: [] as any,
        emoji: {
            url: '',
            map: [] as any,
            visible: false,
            decoder: null as any,
        },
        chatTell: [] as any,
        chatNum: 0,
        ws: null, //建立的连接
        lockReconnect: false, //是否真正建立连接 
        timeout: 3000, //15秒一次心跳
        timeoutObj: 1000, //心跳心跳倒计时
        serverTimeoutObj: 1000, //心跳倒计时
        timeoutnum: 1000, //断开 重连倒计时
        chatMsg: null
    },
    getters: {
    },
    mutations: {
        activeMenu(state: any, value: any) {
            state.activeMenu = value
        },
        areaData(state: any, value: any) {
            state.areaData = value
        },
        chatTell(state: any, value: any) {
            state.chatTell = value
        },
        LOGIN_IN(state: any, value: any) {
            state.token.accessToken = value.accessToken
            state.userInfo = value.userInfo
            state.systemConfig = value.systemConfig
        },
        LOGIN_OUT(state: any) {
            state.userInfo = {}
            state.token.accessToken = ''
            state.systemConfig = {}
            this.commit("stopws");
            window.localStorage.removeItem('vuex')
            window.localStorage.clear()
        },
        SET_EMOJI_LIST(state: any, value: any) {
            state.emojiList = value
        },
        chatNum(state: any, value: any) {
            state.chatNum = value.chatNum
        },
        //初始化ws 用户登录后调用
        webSocketInit(state: any) {
            let that = this
            let param = { token: state.token.accessToken, type: 2 }
            let auth = Base64.encode(JSON.stringify(param))
            // state.ws = new WebSocket('ws:' + state.systemConfig.wsUrl + '?auth=' + auth);
            state.ws = new WebSocket('ws://59.110.217.33:9529/txwWS?auth=' + auth);
            state.ws.onopen = function (res: any) {
                console.log("连接成功")
                that.commit("start");
            }
            state.ws.onmessage = function (res: any) {
                let msg = JSON.parse(res.data)
                if (msg.type === 1) that.commit("reset");
                else state.chatMsg = msg
            }
            state.ws.onclose = function (res: any) {
                console.log("连接已断开," + state.timeoutnum / 1000 + "秒后重连");
                that.commit('reconnect');
            }
            state.ws.onerror = function (res: any) {
                console.log("连接异常");
                that.commit('reconnect');
            }
        },
        reconnect(state: any) {
            //重新连接
            let that = this;
            if (state.lockReconnect) return;
            state.lockReconnect = true;
            state.timeoutnum && clearTimeout(state.timeoutnum);
            state.timeoutnum = setTimeout(() => {
                that.commit('webSocketInit')
                state.lockReconnect = false;
            }, 5000);
        },
        reset(state: any) {
            let that = this;
            clearTimeout(state.timeoutObj);
            clearTimeout(state.serverTimeoutObj);
            that.commit('start')
        },
        start(state: any) {
            let that = this;
            state.timeoutObj && clearTimeout(state.timeoutObj);
            state.serverTimeoutObj && clearTimeout(state.serverTimeoutObj);
            state.timeoutObj = setTimeout(() => {
                if (state.ws.readyState === 1) {
                    let heart = { type: 1 }
                    state.ws.send(JSON.stringify(heart));
                } else {
                    that.commit('reconnect');
                }
            }, state.timeout);
        },
        stopws(state: any) {
            state.ws.close();
            state.ws = null
            state.timeoutObj && clearTimeout(state.timeoutObj);
            state.serverTimeoutObj && clearTimeout(state.serverTimeoutObj);
            state.timeoutnum && clearTimeout(state.timeoutnum);
        },
        sendMsg(state: any, msg: any) {
            state.ws.send(JSON.stringify(msg));
        }
    },
    actions: {
        webSocketInit({
            commit
        }, url) {
            commit('webSocketInit', url)
        },
        webSocketSend({
            commit
        }, p) {
            commit('webSocketSend', p)
        }
    },
    modules: {
    },
    plugins: [
        persistedState({
            storage: {
                setItem: (key, value) => ls.set(key, value),
                getItem: (key) => ls.get(key),
                removeItem: (key) => ls.remove(key)
            }
        })
    ]
})
