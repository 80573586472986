import { createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import XEUtils from 'xe-utils';
import VXETable from 'vxe-table';
// 自定义全局的格式化处理函数
VXETable.formats.mixin({
  // 格式化页面菜单类型
  formatPageMenuType({
    cellValue
  }) {
    return cellValue === 1 ? '首页菜单' : '首页版栏';
  },
  // 格式化岗位状态
  formatJobType({
    cellValue
  }) {
    if (cellValue === 1) return '待审核';else if (cellValue === 2) return '审核不通过';else if (cellValue === 3) return '正在招';else if (cellValue === 4) return '已招满';else if (cellValue === 5) return '已取消';else return '';
  },
  // 格式化页面菜单链接类型
  formatPageMenuLinkType({
    cellValue
  }) {
    return cellValue === 1 ? '内连' : '外连';
  },
  // 格式化用户状态
  formatUserStatus({
    cellValue
  }) {
    return cellValue === 1 ? '正常' : cellValue === 2 ? '测试' : '禁用';
  },
  // 格式化性别
  formatSex({
    cellValue
  }) {
    return cellValue === 1 ? '男' : cellValue === 2 ? '女' : '无';
  },
  // 格式化下拉选项
  formatSelect({
    cellValue
  }, list) {
    const item = list.find(item => item.value === cellValue);
    return item ? item.label : '';
  },
  // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
  formatDate({
    cellValue
  }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss');
  },
  // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
  formatDateYMD({
    cellValue
  }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd');
  },
  // 四舍五入金额，每隔3位逗号分隔，默认2位数
  formatAmount({
    cellValue
  }, digits = 2) {
    return XEUtils.commafy(XEUtils.toNumber(cellValue), {
      digits
    });
  },
  // 格式化银行卡，默认每4位空格隔开
  formatBankcard({
    cellValue
  }) {
    return XEUtils.commafy(XEUtils.toString(cellValue), {
      spaceNumber: 4,
      separator: ' '
    });
  },
  // 四舍五入,默认两位数
  formatFixedNumber({
    cellValue
  }, digits = 2) {
    return parseFloat(XEUtils.toFixed(XEUtils.round(cellValue, digits), digits));
  },
  // 向下舍入,默认两位数
  formatCutNumber({
    cellValue
  }, digits = 2) {
    return parseFloat(XEUtils.toFixed(XEUtils.floor(cellValue, digits), digits));
  },
  // 转换 moment 类型为字符串
  toMomentString({
    cellValue
  }, format) {
    return cellValue ? cellValue.format(format) : '';
  }
});
VXETable.formats.add('myFormat', ({
  cellValue
}, params) => {
  for (let i = 0; i < params.length; i++) {
    if (cellValue === params[i].label) {
      return params[i].value;
    }
  }
  return '';
});
VXETable.formats.add('myFormatHtml', ({
  cellValue
}, color) => {
  return cellValue ? "<label style='color:" + color + ";'>" + cellValue + "</label>" : '';
});
VXETable.formats.add('myFormatImg', ({
  cellValue
}, width, height) => {
  return cellValue ? "<img src='" + cellValue + "' lazy style='display: flex;width:" + width + "px;height:" + height + "px'></img>" : '';
});
// 创建一个简单的超链接渲染
VXETable.renderer.add('myRenderStatus', {
  // 默认显示模板
  renderDefault(renderOpts, params) {
    const props = renderOpts.par;
    let {
      row,
      column
    } = params;
    for (let i = 0; i < props.length; i++) {
      if (row[column.property] == props[i].label) {
        let color = "color:" + props[i].color + ";";
        return [_createVNode("label", {
          "style": color
        }, [props[i].value])];
      }
    }
    return [''];
  }
});
// 创建一个空内容渲染
VXETable.renderer.add('NotData', {
  // 空内容模板
  renderEmpty(h, renderOpts) {
    return [_createVNode("span", null, [_createVNode("img", {
      "style": "width: 200px;",
      "src": "static/img/empty.png"
    }, null), _createVNode("p", null, [_createTextVNode("\u4EB2\uFF0C\u6CA1\u6709\u66F4\u591A\u6570\u636E\u4E86\uFF01")])])];
  }
});
export default VXETable;